import styled from 'styled-components'
import { clearfix } from 'styles/clearfix'

const Grid = styled.div`
  @media ${props => props.theme.mediaQuery.mediumUp} {
    ${clearfix}
  }
`

export default Grid
