import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroImageGradient = ({ heading, image }) => {
  return (
    <>
      <HeroImageGradientImageContainer>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt=''
          objectFit='cover'
          objectPosition='50% 0%'
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        />
      </HeroImageGradientImageContainer>
      <HeroImageGradientContent>
        <Heading>{heading}</Heading>
      </HeroImageGradientContent>
    </>
  )
}

HeroImageGradient.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const HeroImageGradientImageContainer = styled.div`
  min-height: 500px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 100%;

  @media (min-width: 800px) {
    height: 700px;
  }
`

const HeroImageGradientContent = styled.div`
  margin: auto;
  padding: 120px 0 50px;
  position: relative;
  width: 85%;
  z-index: 1;

  @media (min-width: 800px) {
    padding: 200px 0 50px;
    width: 75%;
  }
`

const Heading = styled.h1`
  color: #fff;
  font-size: 2.25em;
  margin: 0;
  text-align: center;
  width: 100%;

  @media (min-width: 800px) {
    font-size: 3em;
  }
`

export default HeroImageGradient
