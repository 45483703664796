import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const H2 = ({ children, textAlign, type, ...props }) => {
  return (
    <StyledH2 as={type} textAlign={textAlign} {...props}>
      {children}
      <Hr textAlign={textAlign} />
    </StyledH2>
  )
}

H2.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.oneOf(['left', 'center']),
  type: PropTypes.oneOf(['h1', 'h2']),
}

H2.defaultProps = {
  textAlign: 'left',
  type: 'h2',
}

const StyledH2 = styled.h2`
  font-size: 1.25em;
  margin: 0 0 30px;
  position: relative;
  text-align: ${props => props.textAlign};

  @media (min-width: 800px) {
    font-size: 1.5em;
    margin: ${props => (props.textAlign === 'center' ? '0 0 40px' : '0 0 30px')};
  }
`

const Hr = styled.hr`
  background-color: #387c2c;
  border: none;
  color: #387c2c;
  height: 2px;
  left: 0;
  margin-top: 10px;
  position: ${props => (props.textAlign === 'center' ? 'relative' : 'absolute')};
  width: 150px;

  @media (min-width: 800px) {
    width: ${props => (props.textAlign === 'center' ? '200px' : '150px')};
  }
`

export default H2
