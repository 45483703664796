import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/agriculture/cutters-shredders/rotary-cutters/',
    imageId: 'rotaryCuttersImage',
    name: 'Rotary Cutters',
    alt: 'John Deere Rotary Cutter',
  },
  {
    link: '/agriculture/cutters-shredders/grooming-mowers/',
    imageId: 'groomingMowersImage',
    name: 'Grooming Mowers',
    alt: 'John Deere Grooming Mower',
  },
  {
    link: '/agriculture/cutters-shredders/flail-mowers-and-shredders/',
    imageId: 'flailMowersShreddersImage',
    name: 'Flail Mowers & Shredders',
    alt: 'John Deere Flail Mowers & Shredders',
  },
]

const CuttersShreddersPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Cutters &amp; Shredders | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Cutters & Shredders',
                'item': 'https://www.hutsoninc.com/agriculture/cutters-shredders/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Cutters &amp; Shredders' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Cutters &amp; Shredders' />

        <Videos
          type='Cutters &amp; Shredders'
          id='R-9zzxKuZuk'
          list='PLUz5fj7f_mw9xybMCBh7f44iob5-NIvNP'
        />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query cuttersShreddersSubcategoryQuery($subcategory: String = "cutters-shredders") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/cutters-shredders-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    rotaryCuttersImage: file(relativePath: { eq: "cutters-shredders/rotary-cutter.jpg" }) {
      ...FloatingGridImage
    }
    groomingMowersImage: file(relativePath: { eq: "cutters-shredders/grooming-mower.jpg" }) {
      ...FloatingGridImage
    }
    flailMowersShreddersImage: file(relativePath: { eq: "cutters-shredders/flail-mower.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default CuttersShreddersPage
