import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FloatingGridImage = ({ src, alt, name }) => {
  return (
    <Container>
      <StyledImg image={getImage(src)} alt={alt} />
      <Hr />
      <h3>{name}</h3>
    </Container>
  )
}

FloatingGridImage.propTypes = {
  alt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.object.isRequired,
}

const Container = styled.div`
  display: inline-block;
  margin: 0;
  padding: 5px 25px;
  text-align: center;

  @media (min-width: 800px) {
    padding: 22px 36px;
  }
`

const StyledImg = styled(GatsbyImage)`
  width: 175px;

  @media (min-width: 800px) {
    width: 225px;
  }
`

const Hr = styled.hr`
  background-color: #387c2c;
  border: none;
  color: #387c2c;
  height: 2px;
  margin-top: 15px;
  width: 75px;
`

export default FloatingGridImage
