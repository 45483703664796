import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Content from 'components/content'

const CategoryContent = ({ children }) => {
  return <StyledContent>{children}</StyledContent>
}

const StyledContent = styled(Content)`
  padding-top: 0;
`

CategoryContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CategoryContent
