import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import H2 from 'components/typography/h2'
import FloatingGridImage from './floating-grid-image'
import { graphql, Link } from 'gatsby'

const FloatingGrid = ({ header, headerType, items, ...props }) => {
  return (
    <Container {...props}>
      {header && (
        <H2 type={headerType} textAlign='center'>
          {header}
        </H2>
      )}
      <Grid>
        {items.map((item, key) => (
          <div key={key}>
            <Link to={item.link}>
              <FloatingGridImage src={item.image} name={item.name} alt={item.alt} />
            </Link>
          </div>
        ))}
      </Grid>
    </Container>
  )
}

FloatingGrid.propTypes = {
  header: PropTypes.string,
  headerType: PropTypes.oneOf(['h1', 'h2']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

FloatingGrid.defaultProps = {
  headerType: 'h2',
}

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 0;
  position: relative;
  text-align: center;
  z-index: 3;
`

const Grid = styled.div`
  a {
    color: #000;
  }

  > div {
    display: inline-block;
  }
`

export const FloatingGridImageFragment = graphql`
  fragment FloatingGridImage on File {
    childImageSharp {
      gatsbyImageData(width: 225)
    }
  }
`

export default FloatingGrid
