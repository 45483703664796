import styled from 'styled-components'
import { column } from 'styles/column'

const Column = styled.div`
  @media ${props => props.theme.mediaQuery.mediumUp} {
    ${props => column(props.columns, props.gutter)}
  }
`

export default Column
