import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import H2 from 'components/typography/h2'
import YouTube from 'components/youtube'

const Videos = ({ id, list, type }) => {
  return (
    <Container>
      <H2>{type} Videos</H2>
      <YouTube videoId={id} list={list} />
    </Container>
  )
}

Videos.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

const Container = styled.div`
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

export default Videos
